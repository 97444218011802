.css-1cqdz6l {
  background-color: white !important;
}

.css-79elbk {
  width: 100%;
}
.css-j93siq {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.css-hji3d9 {
   overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}