.react-multi-email {
  min-height: 40px !important;
padding: 0.2em 0 0.2em 15px;
  border-color: #E2E8F0;
}

.react-multi-email * {
  height: 100%;
}

.react-multi-email > span[data-placeholder] {
  padding: 0.2em;
}

.react-multi-email > span[data-placeholder="true"] {
  color: #718096 !important;
  padding-left: 10px;
}